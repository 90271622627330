<template>
  <div id="categories-category">
    <v-row class="justify-start">
      <v-col cols="12" sm="12">
        <div class="display-1">CRM Category Selection
          <v-btn
          @click="next()"
          class="float-right btns color-white"
          color="#109CF1"
          elevation="0"
          >Next</v-btn>
          <v-btn
            @click="$router.go(-1)"
            class="float-right btns color-white mr-4"
            color="#109CF1"
            elevation="0"
            >Back</v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="loading.countries && loading.categories">
      <v-col cols="12" sm="12" class="h-screen">
         <v-progress-circular
             id="sharethis-loading"
            :size="50"
            color="#109CF1"
            indeterminate
          ></v-progress-circular>
      </v-col>
    </v-row>
    <v-row class="justify-between" v-else>
      <v-col cols="12" sm="5" class="mt-6">
          <div class="display-5 my-5 font-weight-bold">Select the Industry</div>
          <v-card
            elevation="1"
            class="mx-auto"
          >
          <v-list flat>
            <v-list-item-group
                v-model="catCheck"
                multiple
              >
              <template v-for="(item, i) in categories">
                <v-list-item 
                  :key="`item-${i}`"
                  :value="item"
                  active-class="text--accent-4"
                  :class="(i%2!=0) ? 'mt-1' : ''"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox
                        @click="selectCategoryOptions"
                        :value='item'
                        v-model="selectCategories"
                        :input-value="active"
                        hide-details
                        color="#109CF1"
                      ></v-checkbox>
                    </v-list-item-action>
                    
                    <v-list-item-content>
                      <v-list-item-title v-text="item"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card>
        <v-card elevation="0" outlined class="mx-auto mt-8">
          <div v-if="!subCategories.length" class="no-sub-cats p-16 text-center">Select industry</div>
          <v-virtual-scroll
             v-else
            :bench="0"
            :items="subCategories"
            height="300"
            item-height="50"
          >
            <template v-slot:default="{ index, item }">
              <v-list-item
                :key="index"
                :style="index % 2 ? '' : 'background-color:#109CF11c'"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    <strong>{{ item }}</strong>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </template>
          </v-virtual-scroll>
        </v-card>
      </v-col>
      <v-col cols="12" sm="5" class="mt-6">
          <div class="display-5 my-5 font-weight-bold">Choose Country</div>
          <v-card
            elevation="1"
            class="mx-auto countries"
          >
          <v-list flat>
            <v-list-item-group
                v-model="country"
                multiple
              >
              <template v-for="(item, i) in countries">
                <v-list-item 
                  :key="`item-${i}`"
                  :value="item.country"
                  active-class="text--accent-4"
                  class="mt-1 country-item"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-action class="mr-3">
                      <v-checkbox
                        @click="selectCountry"
                        :value='item.country'
                        v-model="selectedCountry"
                        :input-value="active"
                        hide-details
                        color="#109CF1"
                      ></v-checkbox>
                    </v-list-item-action>
                     <v-list-item-icon>
                      <img :src="item.flag" alt="" class="icon">
                    </v-list-item-icon>
                    <v-list-item-content class="ml-3">
                      <v-list-item-title v-text="item.country"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading:{
         countries:true,
         categories:true,
      },
      country:[],
      selectedCountry:[],
      countries:[],
      catCheck:[],
      showSubCategories: false,
      selectCategories: [],
      selectSubCategory: "",
      subCategories: [],
      categoriesSubCategories: {},
      categories: [],
    };
  },
  mounted() {
    this.get__categories__countries();
  },
  methods: {
    async get__categories__countries() {
      await this.$http
        .post("/crm_target_selection_options")
        .then((res) => {
          this.setCategories(res.data.categories);
          this.countries=res.data.countries;
          this.loading={
            countries:false,
            categories:false,
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    selectCountry(){

    },
    setCategories(data) {
      this.categoriesSubCategories = data;
      this.categoriesSubCategories.forEach((el) => {
        this.categories.push(el.category);
      });
      this.selectCategories = this.$store.getters.getSelectCategories;
      this.selectCategoryOptions()
      this.showSubCategories = this.subCategories ? true : false;
        this.selectCategories && this.selectSubCategory ? false : true;
    },
    next() {
      this.$store.commit("setSelectCategories", this.selectCategories);
      this.$store.commit("setSelectSubCategory", this.selectSubCategory);
      this.$store.commit("setStepper", { type: "sharethis", val: 2 });
      this.$router.push({ path: "/crm-filtering" });
    },
    selectCategoryOptions() {
      this.$store.commit("setSelectCategories", this.selectCategories);
      this.showSubCategories = true;
      if (this.selectCategories.length>0) {
        this.subCategories = []
        this.selectCategories.forEach(item => {
          var cat=this.categoriesSubCategories.find(
            (el) => el.category == item
          ).subcategories
          if (cat) {
            this.subCategories = [...this.subCategories,...cat]
          }
        }); 
      } 
      else{
        this.showSubCategories = false;
        this.subCategories = []

      }
    },
  },
};
</script>

<style lang="scss" scoped>
#categories-category::v-deep {
  .countries .v-item-group{
        display: grid;
    grid-template-columns: 50% 50%;
  }
  .country-item{
    width:100% !important;
  }
  .v-virtual-scroll::-webkit-scrollbar {
    width: 5px;
  }
  .v-virtual-scroll {
    scrollbar-width: thin;
  }
  .v-virtual-scroll::-webkit-scrollbar-track {
    border: 0px solid white;
    background: #109CF11c;
    border-radius: 2px;
  }
  .v-virtual-scroll::-webkit-scrollbar-thumb {
    background-color: #109CF1;
    border-radius: 2px;
    border: 3px solid #109CF1;
  }
  .select-category .v-input__slot .v-label,
  .select-category .v-input__slot .v-icon::before {
    color: black !important;
  }
}
</style>