var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"categories-category"}},[_c('v-row',{staticClass:"justify-start"},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('div',{staticClass:"display-1"},[_vm._v("CRM Category Selection "),_c('v-btn',{staticClass:"float-right btns color-white",attrs:{"color":"#109CF1","elevation":"0"},on:{"click":function($event){return _vm.next()}}},[_vm._v("Next")]),_c('v-btn',{staticClass:"float-right btns color-white mr-4",attrs:{"color":"#109CF1","elevation":"0"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("Back")])],1)])],1),(_vm.loading.countries && _vm.loading.categories)?_c('v-row',[_c('v-col',{staticClass:"h-screen",attrs:{"cols":"12","sm":"12"}},[_c('v-progress-circular',{attrs:{"id":"sharethis-loading","size":50,"color":"#109CF1","indeterminate":""}})],1)],1):_c('v-row',{staticClass:"justify-between"},[_c('v-col',{staticClass:"mt-6",attrs:{"cols":"12","sm":"5"}},[_c('div',{staticClass:"display-5 my-5 font-weight-bold"},[_vm._v("Select the Industry")]),_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":"1"}},[_c('v-list',{attrs:{"flat":""}},[_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.catCheck),callback:function ($$v) {_vm.catCheck=$$v},expression:"catCheck"}},[_vm._l((_vm.categories),function(item,i){return [_c('v-list-item',{key:("item-" + i),class:(i%2!=0) ? 'mt-1' : '',attrs:{"value":item,"active-class":"text--accent-4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"value":item,"input-value":active,"hide-details":"","color":"#109CF1"},on:{"click":_vm.selectCategoryOptions},model:{value:(_vm.selectCategories),callback:function ($$v) {_vm.selectCategories=$$v},expression:"selectCategories"}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item)}})],1)]}}],null,true)})]})],2)],1)],1),_c('v-card',{staticClass:"mx-auto mt-8",attrs:{"elevation":"0","outlined":""}},[(!_vm.subCategories.length)?_c('div',{staticClass:"no-sub-cats p-16 text-center"},[_vm._v("Select industry")]):_c('v-virtual-scroll',{attrs:{"bench":0,"items":_vm.subCategories,"height":"300","item-height":"50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('v-list-item',{key:index,style:(index % 2 ? '' : 'background-color:#109CF11c')},[_c('v-list-item-content',[_c('v-list-item-title',[_c('strong',[_vm._v(_vm._s(item))])])],1)],1),_c('v-divider')]}}])})],1)],1),_c('v-col',{staticClass:"mt-6",attrs:{"cols":"12","sm":"5"}},[_c('div',{staticClass:"display-5 my-5 font-weight-bold"},[_vm._v("Choose Country")]),_c('v-card',{staticClass:"mx-auto countries",attrs:{"elevation":"1"}},[_c('v-list',{attrs:{"flat":""}},[_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}},[_vm._l((_vm.countries),function(item,i){return [_c('v-list-item',{key:("item-" + i),staticClass:"mt-1 country-item",attrs:{"value":item.country,"active-class":"text--accent-4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',{staticClass:"mr-3"},[_c('v-checkbox',{attrs:{"value":item.country,"input-value":active,"hide-details":"","color":"#109CF1"},on:{"click":_vm.selectCountry},model:{value:(_vm.selectedCountry),callback:function ($$v) {_vm.selectedCountry=$$v},expression:"selectedCountry"}})],1),_c('v-list-item-icon',[_c('img',{staticClass:"icon",attrs:{"src":item.flag,"alt":""}})]),_c('v-list-item-content',{staticClass:"ml-3"},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.country)}})],1)]}}],null,true)})]})],2)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }